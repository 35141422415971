import React from 'react';

import background from '../../assets/background2.png';
import checkbox from '../../assets/checkbox.png';


function CTA2() {



  return (
    <div id = "tryregchat" style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
<div className="min-h-[350px] flex flex-col md:flex-row items-center justify-center space-y-8 p-8 md:p-24 space-x-8 bg-white/50">
    <img src={checkbox} className='border  rounded-xl max-w-[400px]'></img>
    <div className='flex flex-col space-y-8 md:p-12  max-w-[700px]'>
        <div className='flex flex-col space-y-4 items-start'>
            <span
                className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight font-bold text-lg text-nowrap text-left"> Regulatory Guidance Navigation </span>
            <p className='text-3xl md:text-5xl text-left font-bold tracking-tight '>
                RegChat™ </p>
        </div>

        <p className='text-gray-500 text-lg text-left'>RegChat™ is a GPT for clinical regulatory guidance questions that
            queries major international regulators with referenced summarizations and multi-agency comparisons.</p>

        <div className="flex flex-row gap-x-4">
            <button
                className='bg-black hover:bg-black/80 text-white p-3 px-6 rounded-xl font-semibold w-fit'
                onClick={() => window.open('https://regchat.com', '_blank')}
            >
                RegChat.com
            </button>

            <button
                className='border border-gray-300 bg-white hover:bg-gray-50 text-gray-900 p-3 px-6 rounded-xl font-semibold w-fit'
                onClick={() => window.open('https://youtu.be/Oq8eKJbQQFs', '_blank')}

            >
                See it work
            </button>
        </div>


    </div>

</div>


    </div>
  );
}

export default CTA2;
